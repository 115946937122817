//console.log('>> Home page');
import '@glidejs/glide/dist/glide.min.js';
import * as AOS from 'aos/dist/aos.js';
//import Masonry from 'masonry-layout';
//import imagesLoaded from 'imagesloaded';

//import './modals';
//import './collapsible';
//import Glide, { Controls } from '@glidejs/glide/dist/glide.modular.esm'

AOS.init();


window.addEventListener('scroll', function () {
    var body = document.body;
    if (window.scrollY > 0) {
        body.classList.add('scrolled');
    } else {
        body.classList.remove('scrolled');
    }
});

// Navbar Button toggle
/*
const menuToggle = document.getElementById('menu-toggle');
const navbar = document.getElementById('navbar__nav');

menuToggle.addEventListener('click', () => {
    navbar.classList.toggle('open');
});
*/

const menuToggle = document.getElementById('menu-toggle');
const navbar = document.getElementById('navbar__nav');
const screenWidth = window.innerWidth;

menuToggle.addEventListener('click', () => {

    navbar.classList.toggle('open');

});

// Hinzufügen von Event-Listenern für Elemente mit der Klasse .nav-link
const navLinks = document.querySelectorAll('.nav-link');
navLinks.forEach(link => {
    link.addEventListener('click', () => {
        if (screenWidth < 1024) {
            navbar.classList.remove('open');
        }
    });
});




// Navbar Button toggle
/*
const menuToggle = document.getElementById('menu-toggle');
const navbar = document.getElementById('navbar__nav');

menuToggle.addEventListener('click', () => {
  navbar.classList.toggle('open');
});
*/

// ScrollTo
document
    .querySelectorAll('a[href^="#"]')
    .forEach(trigger => {
        trigger.onclick = function (e) {
            e.preventDefault();
            let hash = this.getAttribute('href');
            let target = document.querySelector(hash);
            let headerOffset = 175;
            let elementPosition = target.offsetTop;
            let offsetPosition = elementPosition - headerOffset;

            console.log(offsetPosition);
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        };
    });



// facebook wall
const news = document.getElementById('news');

if (news) {
    document.addEventListener("DOMContentLoaded", function () {
        const accessTokenNever = 'EAALjU9NJug0BOZCaqoDrDZCZAwq6oFsNBs5IrahZBH8dIhvAufZBq8dd6VsjLVnk5JtICajSlet7xGSQxDMklsvuxHd0ZBtbx2PBxZABfDRf3wJQtW0fYH3WpwJwtKK1lZCtN7t1ZCpS0YSU9FwjrwrC7DUvoA0rghl3PXrsX4jtvk8tnHN14QuMuDWUY3jVmaWmQQC5l1ANw9Vl2xfIZD';
        const pageId = '120246371003545';
        const fields = 'message,full_picture,message_tags,story,created_time,permalink_url,attachments';
        const limit = 6;
        // Ziel-HTML-Element
        const feedContainer = document.getElementById('feed-container');

        // API-Anfrage, um den Feed der Seite abzurufen
        //https://graph.facebook.com/v17.0/120246371003545/feed?limit=10&fields=message,full_picture,message_tags,story,created_time,permalink_url,attachments&access_token=EAADMyCFZB9m8BAIpcTrqGQPZCasWQJsNLZBMFUlVLxllWkIJtRzKuCqCTzEhsPLcAkTivSsGpURgXPdVtTsZBGQ47h4QANZB6EHKNVEsH0nFnTlYlyhRaPYOj6MesTCy5uQE67hx9IBSAAN7CZC1pNuUfObKxb3YymPuYkSrJFKVcKXOQUAhoX35REKthDXa4AJZBic5yxtgwZDZD
        fetch(`https://graph.facebook.com/v17.0/${pageId}/feed?limit=${limit}&fields=${fields}&access_token=${accessTokenNever}`)
            .then(response => response.json())
            .then(data => {
                preloader.style.display = 'none';
                // Verarbeite die Antwortdaten
                if (data.error) {
                    feedContainer.innerHTML = `Fehler beim Abrufen des Feeds: ${data.error.message}`;
                } else {
                    const feed = data.data;
                    let html = '';
                    for (let i = 0; i < feed.length; i++) {
                        const post = feed[i];
                        // timestamp umwandeln
                        const timestamp = post.created_time;
                        const date = new Date(timestamp);
                        const formattedTimestamp = `${date.getDate().toString().padStart(2, "0")}.${(date.getMonth() + 1).toString().padStart(2, "0")}.${date.getFullYear()}`;
                        /*var searchString = "Profilbild";
                        if (post.message.includes(searchString)) {
                            console.log("Die Zeichenfolge wurde gefunden.");
                        } else {
                            console.log("Die Zeichenfolge wurde nicht gefunden.");
                        }
                        */
                        html += `
                        <div class="card">
                        <p class="timestamp text-end mb-0">${formattedTimestamp}</p>
                        `;

                        // falls message leer ist, zb. nur ein Bild gepostet wurde
                        if (!post.message) {
                            post.message = ''
                        }
                        // Überprüfe, ob das Post-Objekt ein Bild enthält
                        if (post.hasOwnProperty('full_picture')) {
                            html += `
                              <a href="${post.permalink_url}" class="main-link" target="_blank">
                                  <img src="${post.full_picture}" alt="Bild ${i + 1}" class="aspect-square object-cover mb-4 post-image" loading="lazy">
                              </a>`;
                        }
                        html += `
                            <div class="card-body">
                                <div class="card-text">
                                    <p class="message text-base">${post.message}</p>
                        `;
                        if (post.hasOwnProperty('story')) {
                            html += `<p class="text-sm"><em>${post.story}</em></p>`;
                        }
                        // braucht mehr berechtigungen (pages_read_engagement)
                        if (post.hasOwnProperty('attachments')) {
                            //html += `<p>Attachment: ${post.attachments}</p>`;

                            const attachments = post.attachments;
                            html += `<div class="attachment font-light text-base">`;
                            attachments.data.forEach(attachment => {
                                //html += `TITLE: ${attachment.title}<br>TYPE: ${attachment.type}<br>URL: ${attachment.url}`;
                                if (attachment.type === 'share') {
                                    html += `<a href="${attachment.url}" class="attach_is_link " target="_blank">Link ansehen</a>`;
                                }
                                if (attachment.type === 'photo') {
                                    // ist schon im header
                                    //html+=`${attachment.url}`;
                                }
                                if (attachment.type === 'event') {
                                    html += `<a href="${attachment.url}" class="attach_is_event" target="_blank">${attachment.title}</a>`;
                                }
                                if (attachment.type === 'album') {
                                    html += `<a href="${attachment.url}" class="attach_is_album" target="_blank">Album ansehen</a>`;
                                }
                                if (attachment.type === 'video_autoplay') {
                                    html += `
                                    <div class="ratio ratio-16x9">
                                        <iframe class="w-full" src="https://www.facebook.com/plugins/video.php?height=314&href=${attachment.url}&show_text=false&t=0" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                                    </div>`;
                                }
                            });

                            html += `</div>`;

                        }

                        if (post.hasOwnProperty('message_tags')) {
                            const messageTags = post.message_tags;
                            html += `<div class="mt-8 tags italic font-light text-base">`;
                            messageTags.forEach(tag => {
                                html += `<span class="tag block"><a href="https://facebook.com/${tag.id}" target="_blank">${tag.name}</a>&nbsp;</span>`;
                            });
                            html += `</div>`;
                        }
                        html += `</div>
                            `

                        //html += `<div class="card-footer url">
                        //<a href="${post.permalink_url}" target="_blank"><img class="icon"></a></div>`;
                        html += `</div></div>`;
                    }
                    feedContainer.innerHTML = html;
                }
                //initMasonry();
            })

            .catch(error => {
                feedContainer.innerHTML = `Fehler beim Abrufen des Feeds: ${error}`;
            });
        /*
        function initMasonry() {
            var grid = document.querySelector('.masonry');
            var msnry;

            imagesLoaded(grid, function () {
                // init Isotope after all images have loaded
                msnry = new Masonry(grid, {
                    itemSelector: '.masonry-item',
                    columnWidth: 0,
                    percentPosition: true
                });
            });
        };
        */
    });
}
